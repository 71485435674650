import React from 'react';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import LeaderBoard from './view/LeaderBoard';
import { PvEDoudizhuDemoView, PvECardView, PvECardView2} from './view/PvEView';
import { DoudizhuReplayView, LeducHoldemReplayView, CardReplayView, CardReplayListView } from './view/ReplayView';

const navbarSubtitleMap = {
    '/leaderboard': '',
    '/replay/card': 'card',
    '/replay/card_list': '对局回放',
    '/replay/leduc-holdem': "Leduc Hold'em",
    '/pve/doudizhu-demo': 'Doudizhu PvE Demo',
    '/pve/card2': '8局制',
    '/pve/card': '先后手',
};

function App() {
    // todo: add 404 page
    return (
        <Router>
            <Navbar subtitleMap={navbarSubtitleMap} />
            <div style={{ marginTop: '75px' }}>
                <Route exact path="/">
                    <Redirect to="/leaderboard?type=game&name=leduc-holdem" />
                    {/* <Redirect to="/pve/doudizhu-demo" /> */}
                </Route>
                <Route path="/leaderboard" component={LeaderBoard} />
                <Route path="/replay/card" component={CardReplayView} />
                <Route path="/replay/card_list" component={CardReplayListView} />
                <Route path="/replay/leduc-holdem" component={LeducHoldemReplayView} />
                <Route path="/pve/doudizhu-demo" component={PvEDoudizhuDemoView} />
                <Route path="/pve/card" component={PvECardView2} />
                <Route path="/pve/card2" component={PvECardView} />

            </div>
        </Router>
    );
}

export default App;
