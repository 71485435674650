import { MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, InputLabel, Radio, RadioGroup, FormControl } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';
import Switch from '@material-ui/core/Switch';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import TranslateIcon from '@material-ui/icons/Translate';
import axios from 'axios';
import { Layout, Message } from 'element-react';
import qs from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../assets/doudizhu.scss';
import { CardGameBoard } from '../../components/GameBoard';
import {
    card2SuiteAndRank,
    computeHandCardsWidth,
    deepCopy,
    fullDoudizhuDeck,
    isDoudizhuBomb,
    shuffleArray,
    sortDoudizhuCards,
    translateCardData,
} from '../../utils';
import { douzeroDemoUrl } from '../../utils/config';
const crypto = require('crypto');

const cardsToRemove = ['RJ', 'BJ'];

let cardFullDoudizhuDeck = deepCopy(fullDoudizhuDeck).filter(card => !cardsToRemove.includes(card));


let shuffledDoudizhuDeck = shuffleArray(cardFullDoudizhuDeck.slice());

// let threeLandlordCards = shuffleArray(sortDoudizhuCards(shuffledDoudizhuDeck.slice(0, 3)));
// let originalThreeLandlordCards = threeLandlordCards.slice();

const initConsiderationTime = 30000;
const considerationTimeDeduction = 1000;
const mainPlayerId = 0; // index of main player (for the sake of simplify code logic)
let playerInfo = [];

let cardNum = 13
let initHands = [
    shuffledDoudizhuDeck.slice(0, cardNum),
    shuffledDoudizhuDeck.slice(cardNum, cardNum * 2),
    shuffledDoudizhuDeck.slice(cardNum * 2, cardNum * 3),
    shuffledDoudizhuDeck.slice(cardNum * 3, cardNum * 4),
];


function generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

console.log('init hands', initHands);
// console.log('three landlord card', threeLandlordCards);

let gameStateTimeout = null;

let gameHistory = [];
let bombNum = {cd1:0, cd2: 0, cd3: 0, cd4: 0};
let playedCardsCd1 = [];
let playedCardsCd2 = [];
let playedCardsCd3 = [];
let playedCardsCd4 = [];
let legalActions = { turn: -1, actions: [] };
let hintIdx = -1;
let gameEndDialogTitle = '';

let newLocale = 'zh'
localStorage.setItem('LOCALE', newLocale);

let syncGameStatus = localStorage.getItem('LOCALE') ? 'ready' : 'localeSelection';

let userId = localStorage.getItem('userId') 
if(!userId){
    userId = generateRandomString(10);
    localStorage.setItem('userId', userId);
}

console.log('userId', userId)


function PvECardView() {
    const { t, i18n } = useTranslation();
    const [apiPlayDelay, setApiPlayDelay] = useState(1000);
    const [isGameEndDialogOpen, setIsGameEndDialogOpen] = useState(false);
    const [considerationTime, setConsiderationTime] = useState(initConsiderationTime);
    const [toggleFade, setToggleFade] = useState('');
    const [gameStatus, setGameStatus] = useState(localStorage.getItem('LOCALE') ? 'ready' : 'localeSelection'); // "localeSelection", "ready", "playing", "paused", "over"
    const [gameState, setGameState] = useState({
        hands: [[], [], [], []],
        latestAction: [[], [], [], []],
        currentPlayer: null, // index of current player
        turn: 0,
    });
    const [selectedCards, setSelectedCards] = useState([]); // user selected hand card
    const [isPassDisabled, setIsPassDisabled] = useState(true);
    const [isHintDisabled, setIsHintDisabled] = useState(true);
    const [toSelectRole, setToSelectRole] = useState(true);
    const [isHumanWin, setIsHumanWin] = useState(false);
    const [predictionRes, setPredictionRes] = useState({ prediction: [], hands: [] });
    const [hideRivalHand, setHideRivalHand] = useState(true);
    const [hidePredictionArea, setHidePredictionArea] = useState(true);
    const [locale, setLocale] = useState(localStorage.getItem('LOCALE') || 'en');
    const [statisticRows, setStatisticRows] = useState([]);
    const [playerNum, setplayerNum] = useState(2);
    const [lastInfoId, setLastInfoId] = useState(-1);
    const [gameId, setGameId] = useState(new Date().getTime());


    const cardArr2DouzeroFormat = (cards) => {
        return cards
            .map((card) => {
                if (card === 'RJ') return 'D';
                if (card === 'BJ') return 'X';
                return card[1];
            })
            .join('');
    };

    function timeout(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    function next_player_pos(){
        return (gameState.currentPlayer + 1) % playerNum;
    }

    function up_player_pos(){
        return (gameState.currentPlayer - 1 + playerNum) % playerNum;
    }

    const proceedNextTurn = async (playingCard, rankOnly = true) => {
        console.log('proceedNextTurn mainPlayerId:' + mainPlayerId + ' proceedNextTurn:' + playingCard +" currentPlayer:" + gameState.currentPlayer)
        console.log('gameHistory:' + JSON.stringify(gameHistory))
        // if next player is user, get legal actions
        if ((gameState.currentPlayer + 1) % playerNum === mainPlayerId) {
            hintIdx = -1;
            const player_hand_cards = cardArr2DouzeroFormat(gameState.hands[mainPlayerId].slice().reverse());
            let rival_move = '';
            let rival_player_position = -1
            let rivalPlayerId = -1
            let is_rival_last_card = 0
            if (playingCard.length === 0) { // 上家出牌为空
                if (playerNum == 2){
                } else if(playerNum == 3){
                    if(gameHistory[gameHistory.length - 1].length > 0){
                        rival_move = gameHistory[gameHistory.length - 1]
                        rivalPlayerId = up_player_pos(gameState.currentPlayer)
                    } 
                } else if(playerNum == 4){
                    if(gameHistory[gameHistory.length - 1].length > 0){
                        rival_move = gameHistory[gameHistory.length - 1]
                        rivalPlayerId = up_player_pos(gameState.currentPlayer)
                    } else if(gameHistory[gameHistory.length - 2].length > 0){
                        rival_move = gameHistory[gameHistory.length - 2]
                        rivalPlayerId = up_player_pos(gameState.currentPlayer)
                        rivalPlayerId = up_player_pos(rivalPlayerId)
                    }
                }
                if(rival_move != ''){
                    rival_move = cardArr2DouzeroFormat(rival_move);
                    rival_player_position = playerInfo[rivalPlayerId].role;
                    is_rival_last_card = gameState.hands[rivalPlayerId].slice().length == 1
                }
               
            } else {
                rivalPlayerId = gameState.currentPlayer;
                rival_move = rankOnly ? playingCard.join('') : cardArr2DouzeroFormat(playingCard);
                rival_player_position = playerInfo[gameState.currentPlayer].role;
                is_rival_last_card = gameState.hands[rivalPlayerId].slice().length == 1
            }

            // 不包含当前出牌
            const requestBody = {
                player_hand_cards:player_hand_cards,
                rival_move:rival_move,
                playerNum:playerNum,
                bombNum: JSON.stringify(bombNum),
                is_rival_last_card: is_rival_last_card
            };

            console.log('requestBody:' + JSON.stringify(requestBody))
            const apiRes = await axios.post(`${douzeroDemoUrl}/legal`, qs.stringify(requestBody));
            const data = apiRes.data;
            console.log('leagle action: ' + JSON.stringify(data))
            legalActions = {
                turn: gameState.turn + 1,
                actions: data.legal_action.split(','),
            };
            setIsHintDisabled(data.legal_action === '');
            setIsPassDisabled(playingCard.length === 0 && gameHistory[gameHistory.length - 1].length === 0);
        }

        // delay play for api player
        if (gameState.currentPlayer !== mainPlayerId) {
            await timeout(apiPlayDelay);
        }

        setToggleFade('fade-out');

        let newGameState = deepCopy(gameState);

        // take played card out from hand, and generate playing cards with suite
        const currentHand = newGameState.hands[gameState.currentPlayer];
        let newHand;
        let newLatestAction = [];
        if (playingCard.length === 0) {
            newHand = currentHand;
            newLatestAction = 'pass';
        } else if (rankOnly) {
            newHand = currentHand.filter((card) => {
                if (playingCard.length === 0) return true;

                const { rank } = card2SuiteAndRank(card);
                const idx = playingCard.indexOf(rank);
                if (idx >= 0) {
                    playingCard.splice(idx, 1);
                    newLatestAction.push(card);
                    return false;
                }
                return true;
            });
        } else {
            newLatestAction = playingCard.slice();
            newHand = currentHand.filter((card) => {
                if (playingCard.length === 0) return true;

                const idx = playingCard.indexOf(card);
                if (idx >= 0) {
                    playingCard.splice(idx, 1);
                    return false;
                }
                return true;
            });
        }

        // update value records for douzero
        const newHistoryRecord = sortDoudizhuCards(newLatestAction === 'pass' ? [] : newLatestAction, true);
        switch (playerInfo[gameState.currentPlayer].role) {
            case 'cd1':
                // lastMoveLandlord = newHistoryRecord;
                playedCardsCd1 = playedCardsCd1.concat(newHistoryRecord);
                break;
            case 'cd2':
                playedCardsCd2 = playedCardsCd2.concat(newHistoryRecord);
                break;
            case 'cd3':
                playedCardsCd3 = playedCardsCd3.concat(newHistoryRecord);
                break;
            case 'cd4':
                playedCardsCd4 = playedCardsCd4.concat(newHistoryRecord);
                break;
            default:
                break;
        }
        gameHistory.push(newHistoryRecord);
        if (isDoudizhuBomb(newHistoryRecord)){
            bombNum[playerInfo[gameState.currentPlayer].role] += 1;
        } 

        newGameState.latestAction[gameState.currentPlayer] = newLatestAction;
        newGameState.hands[gameState.currentPlayer] = newHand;
        newGameState.currentPlayer = (newGameState.currentPlayer + 1) % playerNum;
        newGameState.turn++;
        if (newHand.length === 0) {
            setGameStatus('over');
            syncGameStatus = 'over';
        }
        setGameState(newGameState);
        setToggleFade('fade-in');
        setTimeout(() => {
            setToggleFade('');
        }, 200);

        if (gameStateTimeout) {
            clearTimeout(gameStateTimeout);
        }

        if (newHand.length === 0) {
            setHideRivalHand(false);
            const winner = playerInfo[gameState.currentPlayer];
            // 人类赢，机器人
            // update game overall history
            let gameStatistics = localStorage.getItem('GAME_STATISTICS')
            if(gameStatistics != undefined){
                gameStatistics = JSON.parse(localStorage.getItem('GAME_STATISTICS'))
            }  
            if(gameStatistics == null || gameStatistics['roundStatic'] == undefined){
                gameStatistics =  {
                    totalGameNum: 0,
                    totalWinNum: 0,
                    cd1GameNum: 0,
                    cd1WinNum: 0,
                    cd1WinScore: 0,
                    cd2GameNum: 0,
                    cd2WinNum: 0,
                    cd2WinScore: 0,
                    cd3GameNum: 0,
                    cd3WinNum: 0,
                    cd3WinScore: 0,
                    cd4GameNum: 0,
                    cd4WinNum: 0,
                    cd4WinScore: 0,
                    roundStatic: {
                      roundCnt: 0,
                      roundPlay: 8,
                      humanTotalWinScore: 0,
                      humanTotalWinNum: 0,
                      robotTotalWinScore:0,
                      robotTotalWinNum: 0,
                      humanWinScore: 0,
                      humanCd1Score:0,
                      humanCd2Score:0,
                      humanWinNum: 0,
                      robotWinScore:0,
                      robotWinNum: 0,
                      robotCd1Score:0,
                      robotCd2Score:0,
                    }
                };
            }

            gameStatistics.totalGameNum += 1;

            gameStatistics.roundStatic.roundCnt += 1;
            let isNewRound = false 
            if(gameStatistics.roundStatic.roundCnt % gameStatistics.roundStatic.roundPlay == 1){
                isNewRound = true;
                gameStatistics.roundStatic.humanWinScore = 0;
                gameStatistics.roundStatic.humanWinNum = 0;
                gameStatistics.roundStatic.robotWinScore = 0;
                gameStatistics.roundStatic.robotWinNum = 0;

                gameStatistics.roundStatic.humanCd1Score = 0;
                gameStatistics.roundStatic.humanCd2Score = 0;
                gameStatistics.roundStatic.robotCd1Score = 0;
                gameStatistics.roundStatic.robotCd2Score = 0;
            }

            if(gameStatistics.roundStatic.roundCnt % gameStatistics.roundStatic.roundPlay == 0){
                setToSelectRole(true)
            } else {
                setToSelectRole(false)
            }
            if(winner.id == mainPlayerId){
                setIsHumanWin(true)
                gameStatistics.roundStatic.humanWinNum += 1;
                gameStatistics.roundStatic.humanTotalWinNum += 1;
            } else {
                setIsHumanWin(false)
                gameStatistics.roundStatic.robotWinNum += 1;
                gameStatistics.roundStatic.robotTotalWinNum += 1;
            }

            let humanScore = 0
            let robotScore = 0
            let cardScore  = 0
            console.log('bomNum', bombNum, 'mainPlayerId', mainPlayerId)
            for (let i = 0; i < playerInfo.length; i++) {
                if (i == mainPlayerId && bombNum[playerInfo[i].role] > 0){
                        humanScore += bombNum[playerInfo[i].role] * 5
                        robotScore -= bombNum[playerInfo[i].role] * 5
                    
                }
                if (i != mainPlayerId && bombNum[playerInfo[i].role] > 0){
                    humanScore -= bombNum[playerInfo[i].role] * 5
                    robotScore += bombNum[playerInfo[i].role] * 5
                }
                
                if(winner.id == i){
                    continue
                }
                console.log("player:" + i +" hand size: "+gameState.hands[i].slice().length);

                cardScore = gameState.hands[i].slice().length
             }

             if (cardScore == 13){
                    // 春天
                console.log("春天")
                cardScore *= 2
            }
            if (winner.id == mainPlayerId){
                humanScore += cardScore
                robotScore -= cardScore
            } else {
                robotScore += cardScore
                humanScore -= cardScore
            }

             console.log('this round robotScore:', robotScore, 'humanScore', humanScore)

            gameStatistics.roundStatic.robotWinScore += robotScore;
            gameStatistics.roundStatic.robotTotalWinScore += robotScore;

            gameStatistics.roundStatic.humanWinScore += humanScore;
            gameStatistics.roundStatic.humanTotalWinScore += humanScore;

            switch (playerInfo[mainPlayerId].role) {
                case 'cd1':
                    gameStatistics.cd1GameNum += 1;
                    if (winner.role === playerInfo[mainPlayerId].role) {
                        gameStatistics.totalWinNum += 1;
                        gameStatistics.cd1WinNum += 1;
                    }
                    gameStatistics.cd1WinScore += humanScore
                    // 人类先手
                    gameStatistics.roundStatic.humanCd1Score += humanScore
                    gameStatistics.roundStatic.robotCd2Score += robotScore
                    break;
                case 'cd2':
                    gameStatistics.cd2GameNum += 1;
                    if (winner.role === playerInfo[mainPlayerId].role) {
                        gameStatistics.totalWinNum += 1;
                        gameStatistics.cd2WinNum += 1;
                    }

                    gameStatistics.cd2WinScore += humanScore
                    gameStatistics.roundStatic.humanCd2Score += humanScore
                    gameStatistics.roundStatic.robotCd1Score += robotScore
                    // 人类后手
                    break;
                // case 'cd3':
                //     gameStatistics.cd3GameNum += 1;
                //     if (winner.role === playerInfo[mainPlayerId].role) {
                //         gameStatistics.totalWinNum += 1;
                //         gameStatistics.cd3WinNum += 1;
                //     }
                //     break;
                // case 'cd4':
                //     gameStatistics.cd4GameNum += 1;
                //     if (winner.role === playerInfo[mainPlayerId].role) {
                //         gameStatistics.totalWinNum += 1;
                //         gameStatistics.cd4WinNum += 1;
                //     }
                //     break;
                default:
                    Message({
                        message: 'Wrong douzero player position',
                        type: 'error',
                        showClose: true,
                    });
            }
            localStorage.setItem('GAME_STATISTICS', JSON.stringify(gameStatistics));
            console.log('gameStatistics:' + JSON.stringify(gameStatistics));
            let curRound = gameStatistics.roundStatic.roundPlay
            if(gameStatistics.roundStatic.roundCnt %  gameStatistics.roundStatic.roundPlay != 0){
                curRound = gameStatistics.roundStatic.roundCnt %  gameStatistics.roundStatic.roundPlay 
            }
            let msgScore = '               人类 (' +gameStatistics.roundStatic.humanWinScore + "/"+ gameStatistics.roundStatic.humanWinNum + "/"+ gameStatistics.roundStatic.humanCd1Score + "/"+ gameStatistics.roundStatic.humanCd2Score + ')                                机器人: (' +gameStatistics.roundStatic.robotWinScore + "/"+ gameStatistics.roundStatic.robotWinNum + "/"+ gameStatistics.roundStatic.robotCd1Score + "/"+ gameStatistics.roundStatic.robotCd2Score + ")                                  对局(" + curRound + "/" +gameStatistics.roundStatic.roundPlay+ ")";
            
            setTimeout(() => {
                gameEndDialogTitle =
                    winner.role === 'cd1' ? '先手赢' : '后手赢';
                gameEndDialogTitle += "                                " + msgScore
                setStatisticRows([
                    {
                        role: '先手',
                        win: gameStatistics.cd1WinNum,
                        total: gameStatistics.cd1GameNum,
                        winRate: gameStatistics.cd1GameNum
                            ? ((gameStatistics.cd1WinNum / gameStatistics.cd1GameNum) * 100).toFixed(2) + '%'
                            : '-',
                            score: gameStatistics.cd1WinScore,
                    },
                    {
                        role: '下家',
                        win: gameStatistics.cd2WinNum,
                        total: gameStatistics.cd2GameNum,
                        winRate: gameStatistics.cd2GameNum
                            ? ((gameStatistics.cd2WinNum / gameStatistics.cd2GameNum) * 100).toFixed(2) +
                              '%'
                            : '-',
                            score: gameStatistics.cd2WinScore,
                        
                    },
                    // {
                    //     role: '对家',
                    //     win: gameStatistics.cd3WinNum,
                    //     total: gameStatistics.cd3GameNum,
                    //     winRate: gameStatistics.cd3GameNum
                    //         ? ((gameStatistics.cd3WinNum / gameStatistics.cd3GameNum) * 100).toFixed(2) +
                    //           '%'
                    //         : '-',
                    // },
                    // {
                    //     role: '上家',
                    //     win: gameStatistics.cd4WinNum,
                    //     total: gameStatistics.cd4GameNum,
                    //     winRate: gameStatistics.cd4GameNum
                    //         ? ((gameStatistics.cd4WinNum / gameStatistics.cd4GameNum) * 100).toFixed(2) +
                    //           '%'
                    //         : '-',
                    // },
                    {
                        role: 'All',
                        win: gameStatistics.totalWinNum,
                        total: gameStatistics.totalGameNum,
                        winRate: gameStatistics.totalGameNum
                            ? ((gameStatistics.totalWinNum / gameStatistics.totalGameNum) * 100).toFixed(2) + '%'
                            : '-',
                    },
                ]);
                // 
                setIsGameEndDialogOpen(true);
            }, 2000);
        } else {
            setConsiderationTime(initConsiderationTime);
            // manually trigger timer if consideration time equals initConsiderationTime
            if (initConsiderationTime === considerationTime) gameStateTimer();
        }
    };

    const requestApiPlay = async () => {
        console.log("requestApiPlay")
        // gather information for api request
        let player_position = playerInfo[gameState.currentPlayer].role;
        // const player_position = playerInfo[gameState.currentPlayer].douzeroPlayerPosition;
        // const player_hand_cards = cardArr2DouzeroFormat(gameState.hands[gameState.currentPlayer].slice().reverse());
        let handCards = new Map();
        for (let i = 0; i < playerInfo.length; i++) {
           handCards.set(playerInfo[i].role, cardArr2DouzeroFormat(gameState.hands[i].slice().reverse()));
        }

        const card_play_action_seq = gameHistory
            .map((cards) => {
                return cardArr2DouzeroFormat(cards);
            })
            .join(',');
        
        
        let jsonObject = {};
        for (let [key, value] of handCards) {
            jsonObject[key] = value;
        }
        handCards = JSON.stringify(jsonObject)
        
        const requestBody = {
            player_position,
            handCards: handCards,
            bombNum:JSON.stringify(bombNum),
            card_play_action_seq,
            playerNum,
            playedCardsCd1:JSON.stringify(playedCardsCd1),
            playedCardsCd2:JSON.stringify(playedCardsCd2),
            playedCardsCd3:JSON.stringify(playedCardsCd3),
            playedCardsCd4:JSON.stringify(playedCardsCd4),
            initHands:JSON.stringify(gameState.initHands)
        };

        try {
            const apiRes = await axios.post(`${douzeroDemoUrl}/predict`, qs.stringify(requestBody));
            const data = apiRes.data;
            console.log('chhoose action: ' + JSON.stringify(data))

            if (data.status !== 0) {
                if (data.status === -1) {
                    // check if no legal action or only one legal action can be made
                    const player_hand_cards = cardArr2DouzeroFormat(
                        gameState.hands[gameState.currentPlayer].slice().reverse(),
                    );
                    let rival_move = '';
                    if (gameHistory[gameHistory.length - 1].length > 0) {
                        rival_move = cardArr2DouzeroFormat(
                            sortDoudizhuCards(gameHistory[gameHistory.length - 1], true),
                        );
                    } else if (gameHistory.length >= 2 && gameHistory[gameHistory.length - 2].length > 0) {
                        rival_move = cardArr2DouzeroFormat(
                            sortDoudizhuCards(gameHistory[gameHistory.length - 2], true),
                        );
                    }

                    const card_play_action_seq = gameHistory
                    .map((cards) => {
                        return cardArr2DouzeroFormat(cards);
                    }).join(',');
                    const requestBody = {
                        player_hand_cards:player_hand_cards,
                        rival_move:rival_move,
                        playerNum:playerNum,
                        bombNum: JSON.stringify(bombNum),
                        card_play_action_seq:card_play_action_seq
                    };

                    console.log('requestBody:' + JSON.stringify(requestBody))
                    const apiRes = await axios.post(`${douzeroDemoUrl}/legal`, qs.stringify(requestBody));
                    console.log('leagle action: ' + JSON.stringify(data))
                    if (apiRes.data.legal_action === '') {
                        proceedNextTurn([]);
                        setPredictionRes({
                            prediction: [['', t('doudizhu.only_choice')]],
                            hands: gameState.hands[gameState.currentPlayer].slice(),
                        });
                    } else if (apiRes.data.legal_action.split(',').length === 1) {
                        proceedNextTurn(apiRes.data.legal_action.split(''));
                        setPredictionRes({
                            prediction: [[apiRes.data.legal_action, t('doudizhu.only_choice')]],
                            hands: gameState.hands[gameState.currentPlayer].slice(),
                        });
                    } else {
                        Message({
                            message: 'Error receiving prediction result, please try refresh the page',
                            type: 'error',
                            showClose: true,
                        });
                    }
                } else {
                    Message({
                        message: `Error: ${apiRes.data.message}`,
                        type: 'error',
                        showClose: true,
                    });
                }
            } else {
                let bestAction = '';
                if (data.result && Object.keys(data.result).length > 0) {
                    const sortedResult = Object.entries(data.result).sort((a, b) => {
                        return Number(b[1]) - Number(a[1]);
                    });
                    setPredictionRes({
                        prediction: sortedResult.map((result) => {
                            return [result[0], data.win_rates[result[0]]];
                        }),
                        hands: gameState.hands[gameState.currentPlayer].slice(),
                    });
                    if (Object.keys(data.result).length === 1) bestAction = Object.keys(data.result)[0];
                    else {
                        bestAction = Object.keys(data.result)[0];
                        let bestConfidence = Number(data.result[Object.keys(data.result)[0]]);
                        for (let i = 1; i < Object.keys(data.result).length; i++) {
                            if (Number(data.result[Object.keys(data.result)[i]]) > bestConfidence) {
                                bestAction = Object.keys(data.result)[i];
                                bestConfidence = Number(data.result[Object.keys(data.result)[i]]);
                            }
                        }
                    }
                }
                proceedNextTurn(bestAction.split(''));
            }
        } catch (err) {
            Message({
                message: 'Error receiving prediction result, please try refresh the page',
                type: 'error',
                showClose: true,
            });
        }
    };

    const toggleHidePredictionArea = () => {
        setHideRivalHand(!hideRivalHand);
        setHidePredictionArea(!hidePredictionArea);
    };


    const playerNumChange = (pNum) => {
        setplayerNum(pNum.props.value)
        console.log('playerNum:'+ pNum.props.value);
    };


    const handleSaveStateInfo = (data) => {
        data['userId'] = userId;
        let gameState = JSON.stringify(data)
        let hashId = crypto.createHash('md5').update(gameState).digest('hex');
        if(lastInfoId != hashId){
            // console.log('hashId', hashId, 'lastInfoId', lastInfoId)
            // console.log('gameId', gameId, 'gameState', gameState)
            setLastInfoId(hashId)

            const requestBody = {
                gameId: gameId,
                gameState: gameState
            };
            axios.post(`${douzeroDemoUrl}/save_record`, qs.stringify(requestBody));
        }
    }

    const handleSelectedCards = (cards) => {
        let newSelectedCards = selectedCards.slice();
        cards.forEach((card) => {
            if (newSelectedCards.indexOf(card) >= 0) {
                newSelectedCards.splice(newSelectedCards.indexOf(card), 1);
            } else {
                newSelectedCards.push(card);
            }
        });
        setSelectedCards(newSelectedCards);
    };

    const handleSelectRole = (role) => {
        console.log('handleSelectRole')
        let playerInfoTemplate = []
        let startIdx = parseInt(role.replace('cd', ''))
        for(let idx = 0; idx < playerNum; idx ++){
            playerInfoTemplate.push(
                {
                    id: idx,
                    index: idx,
                    role: 'cd' + startIdx,
                    douzeroPlayerPosition: idx
                }
            )
            startIdx += 1
            if (startIdx > playerNum){
                startIdx = 1
            } 
        }
        playerInfo = playerInfoTemplate;
        console.log('playerInfo:' + JSON.stringify(playerInfo))

        setGameStatus('playing');
        syncGameStatus = 'playing';
    };

    const gameStateTimer = () => {
        gameStateTimeout = setTimeout(() => {
            let currentConsiderationTime = considerationTime;
            if (currentConsiderationTime > 0) {
                currentConsiderationTime -= considerationTimeDeduction;
                currentConsiderationTime = Math.max(currentConsiderationTime, 0);
                setConsiderationTime(currentConsiderationTime);
            } else {
                // consideration time used up for current player
                // if current player is controlled by user, play a random card
                // todo
            }
        }, considerationTimeDeduction);
    };

    const handleReplay = ()=>{
        const url = '/replay/card_list?userId=' + userId;
        window.open(url, '_blank'); // '_blank' 表示在新窗口或标签页中打开链接
    }

    const handleResetStatistics = () => {
        localStorage.removeItem('GAME_STATISTICS');
        setStatisticRows([
            {
                role: '先手',
                win: 0,
                total: 0,
                winRate: '-',
            },
            {
                role: '下家',
                win: 0,
                total: 0,
                winRate: '-',
            },
            {
                role: '对家',
                win: 0,
                total: 0,
                winRate: '-',
            },
            {
                role: '上家',
                win: 0,
                total: 0,
                winRate: '-',
            },
        ]);
    };

    const handleCloseGameEndDialog = () => {
        console.log('handleCloseGameEndDialog')
        // reset all game state for new game
        shuffledDoudizhuDeck = shuffleArray(cardFullDoudizhuDeck.slice());

        initHands = [
            shuffledDoudizhuDeck.slice(0, cardNum),
            shuffledDoudizhuDeck.slice(cardNum, cardNum * 2),
            shuffledDoudizhuDeck.slice(cardNum * 2, cardNum * 3),
            shuffledDoudizhuDeck.slice(cardNum * 3, cardNum * 4),
        ];
        playerInfo = [];

        setGameId(new Date().getTime())
        
        gameStateTimeout = null;
        gameHistory = [];
        bombNum = {cd1:0, cd2: 0, cd3: 0, cd4: 0};
     
        playedCardsCd1 = [];
        playedCardsCd2 = [];
        playedCardsCd3 = [];
        playedCardsCd4 = [];
        legalActions = { turn: -1, actions: [] };

        setConsiderationTime(initConsiderationTime);
        setToggleFade('');
        setIsPassDisabled(true);
        setIsHintDisabled(true);
        setGameState({
            hands: [[], [], []],
            latestAction: [[], [], []],
            currentPlayer: null, // index of current player
            turn: 0,
        });
        setSelectedCards([]); // user selected hand card
        setPredictionRes({ prediction: [], hands: [] });
        setHideRivalHand(hidePredictionArea);

        setGameStatus('ready');
        syncGameStatus = 'ready';
        if(!toSelectRole){
            if(isHumanWin){
                handleSelectRole("cd1");
            } else {
                handleSelectRole("cd2");
            }
        }
        setIsGameEndDialogOpen(false);
    };

    const startGame = async () => {
        // start game
        console.log('playerInfo', playerInfo)
        setGameStatus('playing');
        syncGameStatus = 'playing';
        const newGameState = deepCopy(gameState);

        // 选择第一家玩家，先出牌
        newGameState.currentPlayer = playerInfo.find((element) => element.role === 'cd1').index;
        newGameState.hands = initHands.map((element) => sortDoudizhuCards(element));
        newGameState.initHands = deepCopy(newGameState.hands)

        // if first player is user, fetch legal actions
        if (newGameState.currentPlayer === mainPlayerId) {
            const player_hand_cards = cardArr2DouzeroFormat(newGameState.hands[mainPlayerId].slice().reverse());
            let rival_move = '';

            const card_play_action_seq = gameHistory
                    .map((cards) => {
                        return cardArr2DouzeroFormat(cards);
                    }).join(',');
            const requestBody = {
            
                player_hand_cards:player_hand_cards,
                rival_move:rival_move,
                playerNum:playerNum,
                bombNum: JSON.stringify(bombNum)
            };
            console.log('requestBody:' + JSON.stringify(requestBody))
            const apiRes = await axios.post(`${douzeroDemoUrl}/legal`, qs.stringify(requestBody));
            const data = apiRes.data;
            console.log('leagle action: ' + JSON.stringify(data))

            legalActions = {
                turn: 0,
                actions: data.legal_action.split(','),
            };
            setIsHintDisabled(data.legal_action === '');
        }

        setGameState(newGameState);
        gameStateTimer();
    };

    useEffect(() => {
        if (syncGameStatus === 'playing') gameStateTimer();
    }, [considerationTime]);

    useEffect(() => {
        if (gameState.currentPlayer !== null && syncGameStatus === 'playing') {
            // if current player is not user, request for API player
            if (gameState.currentPlayer !== mainPlayerId) {
                requestApiPlay();
            } else {
                setPredictionRes({ prediction: [], hands: [] });
            }
        }
    }, [gameState.currentPlayer]);

    useEffect(() => {
        if (gameStatus === 'playing') startGame();
    }, [gameStatus]);

    const handleMainPlayerAct = (type) => {
        console.log('handleMainPlayerAct')
        switch (type) {
            case 'play': {
                // check if cards to play is in legal action list
                if (gameState.turn === legalActions.turn) {
                    if (
                        legalActions.actions.indexOf(cardArr2DouzeroFormat(sortDoudizhuCards(selectedCards, true))) >= 0
                    ) {
                        proceedNextTurn(selectedCards, false);
                    } else {
                        Message({
                            message: 'Selected cards are not legal action',
                            type: 'warning',
                            showClose: true,
                        });
                        setSelectedCards([]);
                    }
                } else {
                    Message({
                        message: 'Legal Action not received or turn info inconsistant',
                        type: 'error',
                        showClose: true,
                    });
                }
                break;
            }
            case 'pass': {
                if (gameState.turn === legalActions.turn 
                        && (legalActions.actions.length > 1 || (legalActions.actions.length == 1 && legalActions.actions[0] != '')) ) {
                    Message({
                        message: '必须出牌',
                        type: 'error',
                        showClose: true,
                    });
                    break
                }
                proceedNextTurn([], false);
                setSelectedCards([]);
                break;
            }
            case 'deselect': {
                setSelectedCards([]);
                break;
            }
            case 'hint': {
                if (gameState.turn === legalActions.turn) {
                    setSelectedCards([]);
                    hintIdx++;
                    if (hintIdx >= legalActions.actions.length) {
                        hintIdx = 0;
                    }
                    const hintRanks = legalActions.actions[hintIdx].split('');
                    let hintCards = [];
                    gameState.hands[gameState.currentPlayer].forEach((card) => {
                        const { rank } = card2SuiteAndRank(card);
                        const idx = hintRanks.indexOf(rank);
                        if (idx >= 0) {
                            hintRanks.splice(idx, 1);
                            hintCards.push(card);
                        }
                    });
                    setSelectedCards(hintCards);
                } else {
                    Message({
                        message: 'Legal Action not received or turn info inconsistant',
                        type: 'error',
                        showClose: true,
                    });
                }
                break;
            }
        }
    };

    const computePredictionCards = (cards, hands) => {
        console.log('computePredictionCards')
        let computedCards = [];
        if (cards.length > 0) {
            hands.forEach((card) => {
                const { rank } = card2SuiteAndRank(card);
                const idx = cards.indexOf(rank);
                if (idx >= 0) {
                    cards.splice(idx, 1);
                    computedCards.push(card);
                }
            });
        } else {
            computedCards = 'pass';
        }

        if (computedCards === 'pass') {
            return (
                <div className={'non-card ' + toggleFade}>
                    <span>{t('doudizhu.pass')}</span>
                </div>
            );
        } else {
            return (
                <div className={'unselectable playingCards loose ' + toggleFade}>
                    <ul className="hand" style={{ width: computeHandCardsWidth(computedCards.length, 10) }}>
                        {computedCards.map((card) => {
                            const [rankClass, suitClass, rankText, suitText] = translateCardData(card);
                            return (
                                <li key={`handCard-${card}`}>
                                    <label className={`card ${rankClass} ${suitClass}`} href="/#">
                                        <span className="rank">{rankText}</span>
                                        <span className="suit">{suitText}</span>
                                    </label>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            );
        }
    };

    const computeProbabilityItem = (idx) => {
        if (gameStatus !== 'ready') {
            if (hidePredictionArea) {
                return (
                    <div className={'playing'}>
                        <div className={'non-card'}>
                            <span>{t('hidden')}</span>
                        </div>
                    </div>
                );
            }
            return (
                <div className={'playing'}>
                    <div className="probability-move">
                        {predictionRes.prediction.length > idx ? (
                            computePredictionCards(predictionRes.prediction[idx][0].split(''), predictionRes.hands)
                        ) : (
                            <NotInterestedIcon fontSize="large" />
                        )}
                    </div>
                    {predictionRes.prediction.length > idx ? (
                        <div className={'non-card'} style={{ marginTop: '0px' }}>
                            <span>{`${t('doudizhu.expected_win_rate')}: ${(
                                Number(predictionRes.prediction[idx][1]) * 100
                            ).toFixed(2)}%`}</span>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            );
        } else {
            return <span className={'waiting'}>{t('waiting...')}</span>;
        }
    };

    const gameSpeedMarks = [
        {
            value: 0,
            label: '0s',
        },
        {
            value: 1,
            label: '1s',
        },
        {
            value: 2,
            label: '3s',
        },
        {
            value: 3,
            label: '5s',
        },
        {
            value: 4,
            label: '10s',
        },
        {
            value: 5,
            label: '30s',
        },
    ];



    const gameSpeedMap = [
        {
            value: 0,
            delay: 0,
        },
        {
            value: 1,
            delay: 1000,
        },
        {
            value: 2,
            delay: 3000,
        },
        {
            value: 3,
            delay: 5000,
        },
        {
            value: 4,
            delay: 10000,
        },
        {
            value: 5,
            delay: 30000,
        },
    ];

    const changeApiPlayerDelay = (newVal) => {
        const found = gameSpeedMap.find((element) => element.value === newVal);
        if (found) setApiPlayDelay(found.delay);
    };

    const sliderValueText = (value) => {
        return value;
    };

    const handleLocaleChange = useCallback((newLocale) => {
        i18n.changeLanguage(newLocale);
        setLocale(newLocale);
        localStorage.setItem('LOCALE', newLocale);
    }, []);

    return (
        <div>
            <Dialog
                disableBackdropClick
                open={isGameEndDialogOpen}
                onClose={handleCloseGameEndDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" style={{ width: '200px' }}>
                    {gameEndDialogTitle}
                </DialogTitle>
                <DialogContent>
                    <TableContainer className="doudizhu-statistic-table" component={Paper}>
                        <Table aria-label="statistic table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('doudizhu.role')}</TableCell>
                                    <TableCell>{t('doudizhu.win')}</TableCell>
                                    <TableCell>{t('doudizhu.total')}</TableCell>
                                    <TableCell>{t('doudizhu.win_rate')}</TableCell>
                                    <TableCell>{'得分'}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {statisticRows.map((row) => (
                                    <TableRow key={'statistic-row-' + row.role}>
                                        <TableCell component="th" scope="row">
                                            {row.role}
                                        </TableCell>
                                        <TableCell>{row.win}</TableCell>
                                        <TableCell>{row.total}</TableCell>
                                        <TableCell>{row.winRate}</TableCell>
                                        <TableCell>{row.score}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleReplay()}>{'查看回放'}</Button>
                    <Button onClick={() => handleResetStatistics()}>{t('reset')}</Button>
                    <Button
                        onClick={() => handleCloseGameEndDialog()}
                        color="primary"
                        variant="contained"
                        autoFocus
                        style={{ margin: '16px' }}
                    >
                        {t('play_again')}
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={'doudizhu-view-container'}>
                <Layout.Row style={{ height: '540px' }}>
                    <Layout.Col style={{ height: '100%' }} span="17">
                        <div style={{ height: '100%' }}>
                            <Paper className={'doudizhu-gameboard-paper'} elevation={3}>
                                <CardGameBoard
                                    showCardBack={gameStatus === 'playing' && hideRivalHand}
                                    handleSelectRole={handleSelectRole}
                                    toSelectRole = {toSelectRole}
                                    isPassDisabled={isPassDisabled}
                                    isHintDisabled={isHintDisabled}
                                    playerNum={playerNum}
                                    gamePlayable={true}
                                    playerInfo={playerInfo}
                                    hands={gameState.hands}
                                    selectedCards={selectedCards}
                                    handleSelectedCards={handleSelectedCards}
                                    latestAction={gameState.latestAction}
                                    mainPlayerId={mainPlayerId}
                                    currentPlayer={gameState.currentPlayer}
                                    considerationTime={considerationTime}
                                    turn={gameState.turn}
                                    toggleFade={toggleFade}
                                    gameStatus={gameStatus}
                                    handleSaveStateInfo = {handleSaveStateInfo}
                                    handleMainPlayerAct={handleMainPlayerAct}
                                    handleLocaleChange={(newLocale) => {
                                        handleLocaleChange(newLocale);
                                        setGameStatus('ready');
                                    }}
                                />
                            </Paper>
                        </div>
                    </Layout.Col>
                    <Layout.Col span="7" style={{ height: '100%' }}>
                        <Paper className={'doudizhu-probability-paper'} elevation={3}>
                            {/* {playerInfo.length > 0 && gameState.currentPlayer !== null ? (
                                <div style={{ padding: '16px' }}>
                                    <span style={{ textAlign: 'center', marginBottom: '8px', display: 'block' }}>
                                        {t('doudizhu.three_landlord_cards')}
                                    </span>
                                    <div className="playingCards" style={{ display: 'flex', justifyContent: 'center' }}>
                                        {sortDoudizhuCards(originalThreeLandlordCards, true).map((card) => {
                                            const [rankClass, suitClass, rankText, suitText] = translateCardData(card);
                                            return (
                                                <div
                                                    key={'probability-cards-' + rankText + '-' + suitText}
                                                    style={{ fontSize: '1.2em' }}
                                                    className={`card ${rankClass} full-content ${suitClass}`}
                                                >
                                                    <span className="rank">{rankText}</span>
                                                    <span className="suit">{suitText}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        height: '112px',
                                        padding: '16px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <span>{t('waiting...')}</span>
                                </div>
                            )}
                            <Divider /> */}
                            <div className={'probability-player'} style={{ height: '19px', textAlign: 'center' }}>
                                {playerInfo.length > 0 && gameState.currentPlayer !== null ? (
                                    <span>
                                        {
                                            playerInfo[gameState.currentPlayer].role
                                        }
                                    </span>
                                ) : (
                                    <span>{t('waiting...')}</span>
                                )}
                            </div>
                            <Divider />
                            <div className={'probability-table with-three-landlord-cards'}>
                                <div className={'probability-item'}>{computeProbabilityItem(0)}</div>
                                <div className={'probability-item'}>{computeProbabilityItem(1)}</div>
                                <div className={'probability-item'}>{computeProbabilityItem(2)}</div>
                            </div>
                        </Paper>
                    </Layout.Col>
                </Layout.Row>
                <div className="game-controller">
                    <Paper className={'game-controller-paper'} elevation={3}>
                        <Layout.Row style={{ height: '51px' }}>
                            <Layout.Col span="4" style={{ height: '51px', lineHeight: '48px' }}>
                                <FormGroup style={{ height: '100%' }}>
                                    <FormControlLabel
                                        style={{ textAlign: 'center', height: '100%', display: 'inline-block' }}
                                        className="switch-control"
                                        control={
                                            <Switch checked={!hidePredictionArea} onChange={toggleHidePredictionArea} />
                                        }
                                        label={t('doudizhu.ai_hand_faceup')}
                                    />
                                </FormGroup>
                            </Layout.Col>
                            <Layout.Col span="1" style={{ height: '100%', width: '1px' }}>
                                <Divider orientation="vertical" />
                            </Layout.Col>

                            <Layout.Col span="3" style={{ height: '51px', lineHeight: '48px' }}>

                                <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">玩家数量</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={playerNum}
                                    label="玩家梳理"
                                    onChange={(e, newVal) => {
                                        playerNumChange(newVal);
                                    }}
                                >
                                    <MenuItem value={2}>2人</MenuItem>
                                    <MenuItem value={3}>3人</MenuItem>
                                    <MenuItem value={4}>4人</MenuItem>
                                </Select>
                                </FormControl>
                            </Layout.Col>
                            <Layout.Col span="1" style={{ height: '100%', width: '1px' }}>
                                <Divider orientation="vertical" />
                            </Layout.Col>
                            
                            <Layout.Col
                                span="3"
                                style={{ height: '51px', lineHeight: '51px', marginLeft: '-2px', marginRight: '-2px' }}
                            >
                                <div style={{ textAlign: 'center' }}>{`${t('turn')} ${gameState.turn}`}</div>
                            </Layout.Col>
                            <Layout.Col span="1" style={{ height: '100%', width: '1px' }}>
                                <Divider orientation="vertical" />
                            </Layout.Col>
                            <Layout.Col span="10">
                                <div>
                                    <label
                                        className={'form-label-left'}
                                        style={{ width: '155px', lineHeight: '28px', fontSize: '15px' }}
                                    >
                                        {t('doudizhu.ai_thinking_time')}
                                    </label>
                                    <div style={{ marginLeft: '160px', marginRight: '30px' }}>
                                        <Slider
                                            value={gameSpeedMap.find((element) => element.delay === apiPlayDelay).value}
                                            getAriaValueText={sliderValueText}
                                            onChange={(e, newVal) => {
                                                changeApiPlayerDelay(newVal);
                                            }}
                                            aria-labelledby="discrete-slider-custom"
                                            step={1}
                                            min={0}
                                            max={5}
                                            track={false}
                                            valueLabelDisplay="off"
                                            marks={gameSpeedMarks}
                                        />
                                    </div>
                                </div>
                            </Layout.Col>
                            <Layout.Col span="1" style={{ height: '100%', width: '1px' }}>
                                <Divider orientation="vertical" />
                            </Layout.Col>
                            <Layout.Col span="3" style={{ height: '100%' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%',
                                        paddingLeft: '10px',
                                    }}
                                >
                                    <TranslateIcon style={{ width: '1.2rem', height: '1.2rem' }} />
                                    <Select
                                        id="language-select"
                                        style={{ width: '100%', textAlign: 'center', marginLeft: '7px' }}
                                        value={locale}
                                        onChange={(e) => handleLocaleChange(e.target.value)}
                                    >
                                        <MenuItem value={'zh'}>中文</MenuItem>
                                        <MenuItem value={'en'}>English</MenuItem>
                                    </Select>
                                </div>
                            </Layout.Col>
                        </Layout.Row>
                    </Paper>
                </div>
            </div>
        </div>
    );
}

export default PvECardView;
