import React from 'react';
import { Link } from 'react-router-dom';
import qs from 'query-string';
import { douzeroDemoUrl } from '../../utils/config';


class CardReplayListView extends React.Component {
    constructor(props) {
        super(props);

        // 假设这是从服务器获取的回放历史数据
        this.state = {
            replayHistory: [
            ]
        };
    }

    componentDidMount() {

        const {userId} = qs.parse(window.location.search);
        console.log('userId', userId);
        const requestUrl = `${douzeroDemoUrl}/get_record_list?userId=${userId}`;
        // 在组件挂载后获取对战历史数据
        fetch(requestUrl) // 假设API端点是/api/replay_history
            .then(response => response.json())
            .then(data => {
                console.log('replayHistory', data.items);
                this.setState({ replayHistory: data.items });
            })
            .catch(error => {
                console.error('Error fetching replay history:', error);
            });
    }
    

    render() {
        return (
            <div>
                <table style={{
                            margin: 'auto', // 水平居中
                            display: 'table', // 确保表格以块级元素显示
                            width: '80%' // 设置表格宽度，您可以根据需要调整
                        }}>
                     <thead>
                        <tr>
                            <th style={{ textAlign: 'center' }}>用户ID</th>
                            <th style={{ textAlign: 'center' }}>对局ID</th>
                            <th style={{ textAlign: 'center' }}>游戏时间</th>
                            <th style={{ textAlign: 'center' }}>回合数</th>
                            <th style={{ textAlign: 'center' }}>操作</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.replayHistory.map((record, index) => (
                            <tr key={index}>
                                
                                <td style={{ textAlign: 'center' }}>{record.userId}</td>
                                <td style={{ textAlign: 'center' }}>{record.gameId}</td>
                                <td style={{ textAlign: 'center' }}>{record.create_at}</td>
                                <td style={{ textAlign: 'center' }}>{record.size}</td>
                                <td style={{ textAlign: 'center' }}>
                                    <a href={`/replay/card?gameId=${record.gameId}`} target="_blank" rel="noopener noreferrer">查看回放</a>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default CardReplayListView;
